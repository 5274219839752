<template>
  <div :class="['switcher', { 'lite': lite }]">
    <label
      v-if="label && !inline"
      :for="id"
      class="switcher-label"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      :checked="checked"
      @change="onChange"
      type="checkbox"
    >
    <label class="switcher-toggle" :for="id">
      <span class="switcher-toggle-visual">
        <span class="switcher-toggle-circle">
        </span>
        <sprite
          v-if="!lite"
          :type="'uncheck'"
          :width="16"
          :height="16"
        />
        <sprite
          v-if="!lite"
          :type="'check'"
          :width="20"
          :height="20"
        />
      </span>
      <span v-if="label && inline" class="switcher-toggle-text">{{ label }}</span>
    </label>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    id: {
      type: String,
      required: true
    },
    checked: {
      type: Boolean,
      required: true
    },
    lite: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    label: String,
  },
  methods: {
    onChange(e) {
      this.$emit('onChange', e.currentTarget.checked);
    }
  }
}
</script>

<style lang="scss" scoped>
.switcher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.lite {
    input:checked + .switcher-toggle {
      .switcher-toggle-circle {
        transform: translateX(18px);
      }
    }

    .switcher-toggle {
      border: 0;
      background-color: var(--grey-lightest) !important;
      width: 44px;
      height: 26px;

      &-circle {
        @include position(absolute, 2px null null 2px);
        @include size(22px);
      }
    }
  }

  &-label {
    @include padding(null null 14px);
    display: block;
    color: var(--grey);
    font-size: rem(14px);
    line-height: 1;
  }

  input {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    appearance: none;

    &:checked {
      + .switcher-toggle {
        .switcher-toggle-visual {
          background-color: var(--primary);
        }

        .switcher-toggle-circle {
          transform: translateX(25px);
          background-color: var(--white);
        }

        .svg {
          &.svg-check {
            opacity: 1;
            transform: translateY(-50%);
          }

          &.svg-uncheck {
            opacity: 0;
            transform: translateY(-50%) scale(.7);
          }
        }
      }
    }
  }

  &-toggle {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &:hover {
      .switcher-toggle-text {
        color: var(--primary);
      }
    }

    &-visual {
      position: relative;
      display: block;
      width: 58px;
      height: 32px;
      transition: background-color .5s $ease-out-quart;
      border-radius: 30px;
      border: 1px solid var(--primary);
    }

    &-circle {
      @include position(absolute, 3px null null 4px);
      @include size(24px);
      content: '';
      z-index: 1;
      // transform: translateX(26px);
      transition: transform .5s $ease-out-quart, background-color .5s $ease-out-quart;
      border-radius: 50%;
      background-color: var(--primary);
    }

    .svg {
      @include position(absolute, 50% null null);
      z-index: 0;
      transform: translateY(-50%);
      transition: opacity .4s $ease-out-quad, transform .4s $ease-out-quad;
      fill: var(--primary);

      &.svg-check {
        left: 7px;
        opacity: 0;
        transform: translateY(-50%) scale(.7);
        fill: var(--white);
      }

      &.svg-uncheck {
        right: 8px;
      }
    }

    &-text {
      @include padding(4px null null);
      margin-left: 8px;
      transition: color .2s $ease-out-quart;
    }
  }
}
</style>
