<template>
  <div class="page-applications">
    <Header
      :title="$t('pages.office.applications.title')"
      :actions="[
        {
          label: $t('actions.save'),
          action: () => { onSave('kleos') },
          disabled: disabled
        },
      ]"
    />

    <h2 class="h2">{{ $t('pages.office.applications.kleos.title') }}</h2>
    <Switcher
      :label="$t('pages.office.applications.kleos.label')"
      :checked="client.uses_kleos === 1"
      :id="'kleos'"
      inline
      @onChange="onChange"
    />

    <LoaderScreen v-if="loading"/>
  </div>
</template>


<script>
// import Switch from "@/components/elements/Switch";
import Switcher from "@/components/elements/Switcher";
import LoaderScreen from "@/components/elements/LoaderScreen";
import Header from "@/components/modules/Header";

export default {
  components: {
    Header,
    Switcher,
    LoaderScreen
  },
  computed: {
    client() {
      const { data } = this.$store.state.client;
      return data;
    },
    disabled() {
      const apps = ['kleos'];
      const { data, originalData } = this.$store.state.client;
      let hasChange = false;

      for (const app of apps) {
        const prop = `uses_${app}`;
        if (data[prop] !== originalData[prop]) {
          hasChange = true;
          break;
        }
      }

      return !hasChange;
    },
    loading() {
      return this.$store.state.client.requests.global !== 0 || this.$store.state.client.requests.applications !== 0;
    },
  },
  beforeUnmount() {
    this.$store.commit('client/updateData', {
      prop: 'uses_kleos',
      value: this.$store.state.client.originalData.uses_kleos
    });
  },
  methods: {
    onChange(e) {
      this.$store.commit('client/updateData', {
        prop: 'uses_kleos',
        value: e ? 1 : 0
      });
    },
    onSave(app) {
      this.$store.dispatch('client/setIntegration', app);
    },
  }
};
</script>

<style lang="scss" scoped>
.page-applications {
  .h2 {
    margin-bottom: 4px;
  }
}
</style>