<template>
  <div class="loader-screen">
    <Loader/>
  </div>
</template>

<script>
import Loader from "@/components/elements/Loader";

export default {
  components: {
    Loader
  }
}
</script>


<style lang="scss" scoped>
.loader-screen {
  @include position(absolute, 0 null null 0);
  @include size(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba($white-dark, .7);
}
</style>