// OK
import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';
import { deepCopy } from '@/helpers';

const client = {
  namespaced: true,
  state: {
    uuid: null,
    lawyers: [],
    data: null,
    originalData: null,
    subscription: null,
    requests: {
      name: 0,
      email_box: 0,
      lawyers: 0,
      global: 0,
      subscription: 0,
      applications: 0
    }
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    updateUuid(state, uuid) {
      state.uuid = uuid;
    },
    updateData(state, { prop, value }) {
      state.data[prop] = value;
    },
    updateLawyers(state, lawyers) {
      state.lawyers = lawyers;
    },
    incRequests(state, type) {
      state.requests[type]++;
    },
    decRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('update', { prop: 'uuid', value: null });
      commit('update', { prop: 'lawyers', value: [] });
      commit('update', { prop: 'data', value: null });
      commit('update', { prop: 'originalData', value: null });
    },
    async getClient({ commit, state }) {
      commit('incRequests', 'global');
      const request = await calls.get('getClient');

      if (request.status === 'success') {
        const { data } = request;
        commit('update', { prop: 'data', value: deepCopy(data) });
        commit('update', { prop: 'originalData', value: deepCopy(data) });
      }

      commit('decRequests', 'global');
    },
    async getClientSubscription({ commit, state }) {
      commit('incRequests', 'subscription');
      const request = await calls.get('getClientSubscription');

      if (request.status === 'success') {
        const { data } = request;
        // data.seats_taken = 18;
        // data.can_access = false;
        commit('update', { prop: 'subscription', value: deepCopy(data) });
      }

      commit('decRequests', 'subscription');
      return request;
    },
    async getLawyers({ commit, state }) {
      commit('incRequests', 'lawyers');
      const url = endpoints.getClientLawyers.replace('{uuid}', state.uuid);
      const request = await calls.get(url);
      if (request.status === 'success') commit('updateLawyers', request.data);
      commit('decRequests', 'lawyers');
    },
    async setClientName({ commit, dispatch, state }, name) {
      if (name === state.originalData.name || state.data.name === state.originalData.name) return;
      commit('incRequests', 'name');
      const request = await calls.post('setClientName', { name: name ? name : state.data.name });

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Le nom du cabinet a bien été enregistré' });
      }

      commit('decRequests', 'name');
      return request;
    },
    async setMailSystem({ commit, dispatch, state }) {
      const { mail_system, email_box } = state.data;
      if (mail_system === state.originalData.mail_system && email_box === state.email_box) return;

      commit('incRequests', 'email_box');
      const params = { mail_system };
      if (mail_system === 1) params.email = email_box;

      const request = await calls.post('setClientMailSystem', params);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Paramètre enregistré' });
      }

      commit('decRequests', 'email_box');
      return request;
    },
    async setIntegration({ commit, dispatch, state }, application) {
      const prop = `uses_${application}`;
      const app = state.data[prop];
      if (app === state.originalData[prop]) return;

      commit('incRequests', 'applications');
      const params = { [prop]: app };
      const request = await calls.post('setClientIntegration', params);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Changement enregistré' });
        dispatch('getClient');
      }

      commit('decRequests', 'applications');
      return request;
    }
  }
}

export default client;


// {
//   "token":"3953fe89-4933-4775-bc68-447cafee4b2c",
//   "name":"Mod\u00e8le PRJ",
//   "metadata":{
//     "name":"Mod\u00e8le PRJ",
//     "type":"document",
//     "source":"lawyer",
//     "country":"be",
//     "language":"fr",
//     "lawcases":[
//       {
//         "id":494,
//         "content":"Je suis au bord de la faillite"
//       },
//       {
//         "id":493,
//         "content":"Je suis en faillite"
//       }
//     ],
//     "anonymous":false,
//     "reference":"Mod\u00e8le PRJ, 11 F\u00e9vrier 2022",
//     "visibility":"private",
//     "lawyerSource":{
//       "id":1442,
//       "name":"Emma Dassy"
//     },
//     "redactionDate":"2022-02-11",
//     "authorLastname":"Dassy",
//     "authorFirstname":"Emma"
//   }
// }
